


.pageNotFound{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #454545;
}

.status-num{
font-weight: bolder;
}

.status-tittle{
  font-size: 30px;
}