.login-section {
  background: #E2F0FF;
}
.forgot-pwd-section {
  background: #E2F0FF;
}
.background-img {
  position: absolute;
  top: 39px;
  right: 0;
}
.background-img img {
  width: 158.79px;
  height: 689.83px;
}
.site-logo {
  width: 142px;
  height: 80px;
}
.logo-section {
  text-align: left;
  padding: 50px 45px;
}
.content {
  padding: 0;
  text-align: left;
}
.content .frame-section {
  margin: auto;
  display: block;
}
.content .frame-section .frame-header {
  font-size: 40px;
  color: #404040;
  font-weight: bold;
}
.content .frame-section .frame-subline {
  font-size: 24px;
  color: #404040;
}
.content .frame-section .frame-img {
  margin: 20px 0;
}
.content .frame-section .frame-img img {
  width: 520px;
  height: 369px;
}
.content .powered-by {
  text-align: left;
  padding: 0;
}
.content .powered-by .powered-by-text {
  font-size: 12px;
  color: #404040;
  margin: 5px 0;
}
.content .powered-by .powered-by-img img {
  width: 141px;
  height: 43px;
}
.login-section .content .form-section {
  width: 412px;
  height: 478px;
  border-radius: 16px;
  box-shadow: 4px 4px 4px rgba(6, 110, 210, 0.2);
  background: #FFFFFF;
  margin: 10px 0 0 0;
  padding: 30px;
  right: 152px;
  bottom: 15px;
  position: absolute;
  color: #404040;
  z-index: 10;
}

.loginheight{
  height: 478px!important;
}
.forgot-pwd-section .content .form-section {
  width: 412px;
  height: 372px;
  border-radius: 16px;
  box-shadow: 4px 4px 4px rgba(6, 110, 210, 0.2);
  background: #FFFFFF;
  margin: 10px 0 0 0;
  padding: 30px;
  right: 152px;
  bottom: 15px;
  position: absolute;
  color: #404040;
  z-index: 10;
}
.content .form-header {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  display: flex;
}
.content .form-msg {
  height: 56px;
}
.content .form-footer-msg {
  height: 55px;
}
.content .form-field {
  margin: 12px 0;
}
.input-height input {
  height: 48px;
}
.login-section .content input[type=password] {
  padding-right: 50px;
}
.login-section .content .eye-icon{
  position: absolute;
  right: 40px;
  top: 280px;
  cursor: pointer;
}
.login-section .content .eye-icon img, .forgot-pwd-section .content .eye-icon img{
  width: 24px;
  height: 24px;
}
.content label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  margin: 5px 0;
}
.table-header-actions .form-check label {
  font-size: 12px;
}
.content .footer-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  margin: 5px 0;
}


.content .submit-btn {
  background: #066ED2;
  border-radius: 0px 0px 16px 16px;
  height: 72px;
  width: 413px;
  position: relative;
  right: 30px;
  cursor: pointer;
  border: none;
}
.content .submit-btn .submit-text {
  color: #FFFFFF;
  text-align: center;
  padding: 22px;
  font-size: 22px;
  line-height: 28px;
}
.login-section .top-container, .forgot-pwd-section .top-container {
  position: relative;
  min-height: 490px;
}
.login-text-error {
  margin: 10px 0;
  position: absolute;
  text-align: center;
  border-radius: 5px;
  margin: 15px 0;
  right: 28px;
  padding: 10px;
  line-height: 20px;
  width: 86%;
  background: #FFE6E6 0% 0% no-repeat padding-box;
  color: #EF4035;
  max-height: 46px;
}
.footer-text-error {
  color: #EF4035;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none !important;
}
.spinner-border {
  margin-right: 10px;
}

.reset-field{
  position: relative;
}

.reset-field2{
  position: relative;
}

.reset-eye-icon{
  position: absolute;
  right: 24px;
  top: 28px;
}
.reset-eye-icon2{
  position: absolute;
  right: 24px;
  top: 28px;
}

.validation-height{
  height: 45px;
}


.toast-abs{
  position: absolute;
  top: -25%;
  /* right:100%!important; */
  left: -100%!important;
  transform: translate(-50%, 0%)!important;
  width: 100%;
}

.toast-abs .toast{
  width: 412px;
}




@media screen and (min-width: 360px){
  .logo-section {
    padding: 25px 15px;
  }
  .content .frame-section .frame-header {
    font-size: 22px;
  }
  .content .frame-section .frame-subline {
    font-size: 18px;
  }
  .login-section .content .form-section {
    top: 90px;
    bottom: 0;
    right: 0;
    width: 330px;
  }
  .forgot-pwd-section .content .form-section {
    top: 90px;
    bottom: 0;
    right: 0;
    width: 330px;
  }
  .content .submit-btn {
    width: 330px;
  }
  .login-section .top-container {
    min-height: 600px;
  }
  .login-section .content .powered-by {
    text-align: center;
    padding: 0 0 5px 0;
  }
  .forgot-pwd-section .content .powered-by {
    text-align: center;
    padding: 15px 0;
  }
}
@media screen and (min-width: 384px){
  .logo-section {
    padding: 25px 15px;
  }
  .content .frame-section .frame-header {
    font-size: 22px;
  }
  .content .frame-section .frame-subline {
    font-size: 18px;
  }
  .login-section .content .form-section {
    top: 90px;
    bottom: 0;
    right: 12px;
    width: 330px;
  }
  .forgot-pwd-section .content .form-section {
    top: 90px;
    bottom: 0;
    right: 12px;
    width: 330px;
  }
  .content .submit-btn {
    width: 330px;
  }
  .login-section .top-container {
    min-height: 600px;
  }
  .login-section .content .powered-by {
    text-align: center;
  }
  .forgot-pwd-section .content .powered-by {
    text-align: center;
  }
}
@media screen and (min-width: 412px){
  .logo-section {
    padding: 25px 15px;
  }
  .content .frame-section .frame-header {
    font-size: 22px;
  }
  .content .frame-section .frame-subline {
    font-size: 18px;
  }
  .login-section .content .form-section {
    top: 90px;
    bottom: 0;
    right: 25px;
    width: 330px;
  }
  .forgot-pwd-section .content .form-section {
    top: 90px;
    bottom: 0;
    right: 25px;
    width: 330px;
  }
  .content .submit-btn {
    width: 330px;
  }
  .login-section .top-container {
    min-height: 600px;
  }
  .content .powered-by {
    text-align: center;
  }
}
@media screen and (min-width: 768px){
  .logo-section {
    padding: 25px 30px;
  }
  .content .frame-section .frame-header {
    font-size: 28px;
  }
  .content .frame-section .frame-subline {
    font-size: 24px;
  }
  .login-section .content .form-section {
    top: 100px;
    bottom: 0;
    right: 170px;
    width: 412px;
  }
  .forgot-pwd-section .content .form-section {
    top: 100px;
    bottom: 0;
    right: 200px;
    width: 412px;
  }
  .content .submit-btn {
    width: 413px;
  }
  .login-section .top-container {
    min-height: 620px;
  }
  .login-section .content .powered-by {
    text-align: center;
    padding: 0 0 15px 0;
  }
  .forgot-pwd-section .content .powered-by {
    text-align: center;
    padding: 0 40px 15px 0;
  }
}
@media screen and (min-width: 1024px){
  .logo-section {
    padding: 50px 45px;
  }
  .content .frame-section .frame-header {
    font-size: 28px;
  }
  .content .frame-section .frame-subline {
    font-size: 24px;
  }
  .login-section .content .form-section {
    top: 0;
    bottom: 15px;
    right: 20px;
  }
  .forgot-pwd-section .content .form-section {
    top: 0;
    bottom: 15px;
    right: 20px;
  }
  .login-section .top-container {
    min-height: 500px;
  }
  .login-section .content .powered-by {
    text-align: left;
    padding: 0 0 5px 0;
  }
  .forgot-pwd-section .content .powered-by {
    text-align: left;
    padding: 0 0 5px 0;
  }
}
@media screen and (min-width: 1170px){
  .logo-section {
    padding: 50px 45px;
  }
  .content .frame-section .frame-header {
    font-size: 40px;
  }
  .content .frame-section .frame-subline {
    font-size: 24px;
  }
  .login-section .content .form-section {
    top: 0;
    bottom: 15px;
    right: 100px;
  }
  .forgot-pwd-section .content .form-section {
    top: 0;
    bottom: 15px;
    right: 100px;
  }
  .login-section .top-container {
    min-height: 500px;
  }
  .content .powered-by {
    text-align: left;
    padding: 0 0 8px 0;
  }
}