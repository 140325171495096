/*CSS Common Classes for quick fixes */
body {
  outline: 0;
}

.z-index-1048 {
  z-index: 1048 !important  ;
}

.btn .badge {
  top: 0 !important;
}

/* viewport height classes */
.vh-25 {
  height: 25vh !important;
}

.vh-40 {
  height: 40vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-55 {
  height: 55vh !important;
}

.vh-60 {
  height: 60vh !important;
}

.vh-70 {
  height: 70vh !important;
}

.vh-80 {
  height: 80vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.lupa-risk #claims-tab-tab-null,
.blank-hipps #claims-tab-tab-null,
.review-choice-demonstration #claims-tab-tab-null,
.referrals #claims-tab-tab-null,
.referrals-pending-order #claims-tab-tab-null {
  padding-left: 0 !important;
}

/* padding */

.py-10 {
  padding-top: 0.785rem !important;
  padding-bottom: 0.785rem !important;
}

/* max-heights */

.max-vh-60 {
  max-height: 60vh;
}

/* global style class */

.fs-10 {
  font-size: 0.65rem;
  /* fontsize - 12px  */
}

.fs-11 {
  font-size: 0.67rem;
  /* fontsize - 12px  */
}

.fs-12 {
  font-size: 0.75rem;
  /* fontsize - 12px  */
}

.fs-14 {
  font-size: 0.875rem !important;
  /* fontsize - 14px  */
}

.fs-16 {
  font-size: 1rem;
  /* fontsize - 16px  */
}

.fs-18 {
  font-size: 1.125rem;
  /* fontsize - 18px  */
}

.h-48 {
  height: 48px !important;
}

.w-300px {
  width: 300px !important;
}

.popover {
  font-family: var(--font-family) !important;
}

.popover-header {
  background-color: #e7f5ff !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* custom modal width */

.modal-90w {
  max-width: 80% !important;
}

.modal-overflow-height {
  height: 430px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.app-secondary-bg-color-static {
  background: rgba(248, 248, 248, 1) !important;
}

.app-modal-header,
.app-card-header {
  background-color: rgba(222, 239, 255, 1);
}

.nested-dropdown .dropdown-menu.show {
  transform: translate(154px, 0px) !important;
}

.nested-dropdown .dropdown-menu.show .UpperArrow {
  left: -18px;
  top: -9px;
  padding: 5px;
  transform: rotate(-90deg);
}

.nested-dropdown.dropdown .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-90deg);
}

.box-shadow-billing {
  box-shadow: 0 4px 22px hsl(0deg 0% 71% / 25%);
}

li {
  cursor: pointer;
  list-style-type: none;
}

.border-radius-5 {
  border-radius: 5px;
}

.pad0 {
  padding: 0px !important;
}

.login-spinner {
  width: 24px !important;
  height: 24px !important;
}

.padlr15 {
  padding: 0px 15px;
}

.padtop15 {
  padding-top: 15px;
}

.vh-60 {
  height: 60vh;
}

/* accordion arrow btn position styles */

.accordion-arrow-btn {
  position: absolute !important;
  right: 10px !important;
  top: 50% !important;
  width: auto !important;
  padding: 6px !important;
  transform: translateY(-50%) !important;
}

.breadcrumb-item.active {
  color: #066ed2 !important;
  font-weight: bold;
}

.breadcrumb-item a {
  color: #6c757d;
  text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: " \2794" !important;
}

.middle-breadcrumb a {
  cursor: default !important;
}

.css-qc6sy-singleValue,
.react-select__single-value {
  color: #6c757d !important;
  font-size: 0.87rem;
}

.tab-right .css-1dimb5e-singleValue {
  margin-left: 5px;
  color: #6c757d !important;
  font-size: 0.87rem;
}

.data-split .card-tittle {
  font-size: 12px;
  line-height: 15px;
  color: #454545;
  margin-bottom: 10px;
}

.range-slider {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  /* width: 250px; */
  z-index: 999;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

datalist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  writing-mode: vertical-lr;
  width: 400px;
}

datalist option {
  padding: 0;
}

.js-plotly-plot .plotly .modebar-group {
  padding: 0px !important;
}

.range-slider input[type="range"] {
  width: 800px;
  margin: 0;
}

/*Chrome*/
/* @media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    overflow: hidden;
    appearance: none;
    -webkit-appearance: none;
    background-color: #E5E5E5;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    appearance: none;
    -webkit-appearance: none;
    color: #13bba4;
    background-color: #E5E5E5;
    margin-top: -1px;
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 10px;
    appearance: none;
    -webkit-appearance: none;
    height: 10px;
    border-radius: 50%;
    background: #066ED2;
    box-shadow: -80px 0 0 80px #066ED2;
  }

}

.range-slider input[type="range"]::-moz-range-progress {
  background-color: #43e5f7; 
}
.range-slider input[type="range"]::-moz-range-track {  
  background-color: #9a905d;
}
/* IE*/
.range-slider input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7;
}

.range-slider input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
}

.align-accordian .accordion-button {
  padding-right: 10px;
}

.secondLevel-accord .accordion-button::after {
  margin-left: 6px !important;
  margin-bottom: 14px;
  position: absolute;
  right: 0;
}

.dots-display {
  position: absolute;
  top: 29px;
  left: 3px;
  width: 800px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  z-index: -1;
}

/* .circle-slide{
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}  */

.padbottom15 {
  padding-bottom: 15px;
}

.transparent-bg {
  background: transparent !important;
}

.bg-f8 {
  background: #f8f8f8;
}

.breadcrumb-section1 {
  margin-top: 15px !important;
}

.billing-customize-breadcrumb ol {
  margin-bottom: 8px;
}

.box-shadow {
  box-shadow: 0 4px 4px hsl(0deg 0% 71% / 25%);
}

.border-e7 {
  border: 1px solid #e7e7e7;
}

.margin-bottom-10 {
  margin: 0px 0px 10px 0px !important;
}

.moveleft {
  float: left;
}

.ft12 {
  font-size: 12px !important;
}

.bld {
  font-weight: bold;
}

.moveright {
  float: right;
}

.margintop-minus40 {
  margin-top: -40px;
}

.datepickblock {
  background: #f8f8f8;
  padding: 0px 10px;
  display: inline-block;
}

.datepickblock .date-picker {
  background: transparent;
}

.datepickblock .react-datepicker-wrapper {
  margin-left: 10px;
  width: auto;
}

.fixed-drop-down .btn:hover {
  background-color: #e7f5ff !important;
}

.btn-check:focus + .btn,
.btn:focus {
  /* color: black; */
  /* background-color: #E7F5FF; */
  /* border: 0.2px solid #E5E5E5; */
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.blocktop {
  display: inline-block;
  margin-right: 50px;
}

.blocktop:last-child {
  margin-right: 0px;
}

.blocktop h3.title {
  font-weight: normal;
}

.blocktop span {
  font-size: 16px;
  font-weight: bold;
}

/* button#dropdown-business-developement {
    margin-bottom: 10px;
  } */

/* button#dropdown-clinical {
  margin-bottom: 10px;
} */

button.dropdown-toggle::after {
  margin-left: 6px !important;
}

button.dropdown-toggle.dropdownseperator::after {
  position: absolute !important;
  right: 15px;
  transform: translateY(-50%);
  top: 50%;
  /* background-color: red; */
}

button.dropdown-toggle.dropdownseperator::before {
  content: "";
  position: absolute;
  top: 15%;
  right: 40px;
  height: 70%;
  background-color: hsl(0, 0%, 80%);
  width: 1px;
}

/* button#dropdown-account {
  margin-bottom: 10px;
} */

.reset-pad-tabs-firstchild .nav-item:first-child {
  padding: 0.5rem 1rem;
}

/*CSS Common Classes for quick fixes */

/* commented for multiple scrollbar issue in data playground */
/* .container-fluid {
  padding: 0!important;
  overflow-x: hidden!important;
  height: auto!important;
} */
/* commented for multiple scrollbar issue in data playground */

.dropdown-menu {
  box-shadow: 0px 2px 20px -1px rgb(0 0 0 / 30%);
  border: none;
  /* transform: translate(0px, 46px)!important; */
}

.page-tabs {
  position: relative;
}

.page-tabs .nav-tabs {
  margin-bottom: 18px;
  background-color: #fff;
  padding-top: 12px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  width: 100% !important;
  border-bottom: 1px solid #f8f8f8 !important;
}

.brudcrumb-patch {
  margin-top: 56px !important;
}

.dropdown-item {
  background: #f8f8f8 !important;
  color: #000000 !important;
  margin: 0.4rem 0.5rem;
  padding: 5px;
  font-size: 14px;
  width: auto !important;
}

.dropdown-item:hover {
  background: #e7f5ff !important;
}

.dropdown-item.active {
  background: #e7f5ff !important;
}

.btn-secondary {
  background-color: #f8f8f8 !important;
  border-color: #f8f8f8 !important;
  color: #000000 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none !important;
  font-size: 14px;
}

.breadcrumb-section {
  text-align: left;
  font-size: 12px;
  margin: 20px 60px;
}

.section-header {
  padding-right: 0px;
  width: 105px;
}

.bucket {
  width: 95px;
}

.section-header-line {
  padding-left: 0px;
}

.tabs-section1 {
  margin: 0px 5px;
  margin-bottom: 5px;
  height: 450px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: #f8f8f8;
}

.rightTab {
  background-color: white;
  padding-left: 25px;
  text-align: left;
}

.button-default {
  font-size: 12px;
}

.buttons {
  border: 1px solid White;
  background-color: white;
  font-size: 12px;
  color: black;
}

.d-grid {
  margin-top: 10px;
}

.tab-container {
  margin: 0px;
}

.source-details {
  text-align: left;
  position: absolute;
  background: #f8f8f8;
  height: 50px;
  padding: 0 10px;
  right: 65px;
  top: 70px;
  width: 225px;
}

.source-details .text {
  color: #2e3748;
  font-size: 12px;
}

.source-details .date {
  color: #2e3748;
  font-weight: bold;
  font-size: 12px;
}

.page-title {
  padding: 0 50px;
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
}

.tbl-page-title {
  padding: 0 50px 20px 0;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}

.tbl-page-table-tittle {
  padding: 0 50px 0 0;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
}

.content {
  margin: 10px 50px;
}

.footer {
  /* height: 200px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.pagination-left {
  width: 245px;
  height: 40px;
  background: #e7f5ff 0% 0% no-repeat padding-box;
  border-radius: 8px;
}

.pagination-left div {
  font-size: 14px;
  color: #302d2d;
  line-height: 18px;
  padding: 10px 0;
  text-align: center;
}

.pagination-center {
  justify-content: center;
  background: #e7f5ff 0% 0% no-repeat padding-box;
  border-radius: 8px;
}

.page-link {
  background-color: #e7f5ff;
  color: #302d2d;
  border: none;
}

.page-item.active .page-link {
  border-radius: 8px;
}

.pagination-right {
  text-align: right;
  position: absolute;
  z-index: 999;
  right: 40px;
}

.modal-comments-hide {
  max-width: 500px !important;
}

.pagination-right .text {
  display: inline;
  padding: 0 10px;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  color: #302d2d;
}

.pagination-right .page-by {
  display: inline;
}

.pagination-right .page-by .dropdown {
  display: inline;
}

.pagination-right .dropdown .btn-secondary {
  background: #e7f5ff !important;
  font-size: 14px;
  color: #302d2d !important;
}

.pagination-right .page-by button {
  width: 60px;
}

.pagination-right .page-by .dropdown-menu {
  width: 60px;
  inset: auto auto 20px 0px !important;
}

.pagination .page-link:focus {
  box-shadow: none !important;
}

.productivity-column h3 {
  font-weight: 700 !important;
  font-size: 1rem !important;
}

.container-body {
  margin-top: 57px;
  background-color: #fff;
  margin-bottom: 40px;
}

.first-row .right,
.first-row .left {
  background-color: #fff !important;
  height: 100%;
}

.productivity-column {
  background-color: #f8f8f8;
  padding: 15px;
  margin: 0 !important;
}

.prod_select_option input {
  height: 24px !important;
}

.productivity-tabs .date-picker {
  padding-left: 0 !important;
}

.date-picker .value {
  margin-top: 14px;
}

.userfilter {
  bottom: auto !important;
}

.call2action {
  position: absolute;
  top: 30px;
  right: 0;
  bottom: 0;
  transform: translateY(-20px);
  padding-right: 15px;
  z-index: 10;
}

.brudcrumb-patch ol {
  padding-top: 10px;
}

.filter-popover .corners {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.filter-popover form {
  width: 254px !important;
}

.filter-popover {
  border: none !important;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  -webkit-box-shadow: -5px -1px 25px -1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -5px -1px 25px -1px rgba(0, 0, 0, 0.3);
  box-shadow: -5px -1px 25px -1px rgba(0, 0, 0, 0.3);
}

.filter-popover .popover-body {
  padding: 10px;
}

.filter-popover .popover-body .group {
  padding: 15 !important;
}

.filter-popover .hover:hover {
  background-color: #f8f8f8;
}

.dateCheck {
  position: relative;
}

.popover-body .date-icon,
.modal-body .date-icon {
  display: inline;
  position: absolute;
  left: 0;
  top: 45%;
  transform: translateY(-50%);
  margin-left: 10px;
}

.filter-popover .value {
  font-weight: bold;
}

.pos-rel {
  position: relative;
}

.pos-rel .date-icon {
  position: absolute;
  display: inline-block;
  top: 45%;
  left: 10;
  transform: translateY(-50%);
}

/* aggrid fonts */

.ag-header-row {
  font-size: 11px;
  word-break: break-word;
}

.ag-row {
  font-size: 11px !important;
  word-break: break-word;
  line-height: 14px;
}

.ag-cell-value {
  line-height: 14px;
  word-break: break-word;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  display: flex;
  align-items: center;
}

/* aggrid fonts */

.filter-modal {
  display: flex !important;
  align-items: center !important;
}

.filter-modal button {
  color: #046ed2;
}

.invalid-txt-input {
  border: 1px solid red !important;
}

@media screen and (min-width: 360px) {
  .breadcrumb-section,
  .tabs-section {
    margin: 10px 15px 0 15px;
  }

  .source-details {
    position: relative;
    right: -10px;
    top: 0;
  }

  .page-title {
    padding: 15px;
  }

  .content {
    margin: 10px 15px;
  }
}

@media screen and (min-width: 768px) {
  .breadcrumb-section,
  .tabs-section {
    margin: 20px 30px 0 30px;
  }

  .source-details {
    position: absolute;
    right: 30px;
    top: 70px;
    width: 220px;
  }

  .page-title {
    padding: 0 30px 20px 30px;
  }

  .content {
    margin: 10px 30px;
  }
}

@media screen and (min-width: 830px) {
  .breadcrumb-section,
  .tabs-section {
    margin: 20px 30px 0 30px;
  }

  .source-details {
    position: absolute;
    right: 35px;
    top: 70px;
    width: 220px;
  }

  .page-title {
    padding: 0 30px 20px 30px;
  }

  .content {
    margin: 10px 30px;
  }
}

@media screen and (min-width: 1170px) {
  .breadcrumb-section,
  .tabs-section {
    margin: 6px 30px;
    padding-top: 6px;
  }

  .source-details {
    position: absolute;
    right: 65px;
    top: 70px;
    width: 225px;
  }

  .page-title {
    padding: 0 50px;
    cursor: auto;
  }

  .content {
    margin: 10px 30px;
  }
}

.update-modal .authorization.ag-theme-alpine.blankhipps-modal {
  height: 25vh !important;
}

.productivity-tabs .left,
.productivity-tabs .right {
  background: #fff;
  padding: 5px 0;
}

.pdgm .productivity-column {
  background-color: #fff;
  padding: 10px;
}

.pdgm-tittle {
  padding-left: 10px;
  font-weight: 600;
  color: #000;
}

.flex-column.gap-2 {
  display: flex;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.flex-column.gap-2 .blue-button,
.view-2,
.details-button .blue-button,
.blue-button.denial-claims {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 8px 15px !important;
  text-align: left;
}

.view-2 {
  margin-left: 15px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 8px 15px !important;
}

.pdgm {
  width: 100%;
  display: block;
}

.accordian.pdgm .accordion-button::after {
  display: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: #fff;
}

.pdgm .active {
  color: #7fb978;
}

.pdgm .no {
  color: #fb7076;
}

.row-border > div {
  border-right: 1px solid #e7e7e7;
  margin-top: 15px;
}

.row-border > div:last-child {
  border-right: none;
}

.pdgm .tbody {
  border-top: none !important;
}

.pdgm tr {
  height: auto !important;
}

.pdgm thead th {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pdgm tbody td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pdgm thead th:first-child,
.pdgm tbody td:first-child {
  padding-left: 25px;
  font-weight: bold;
  font-size: 13px;
}

.financial-details .no {
  color: #fb7076;
}

.financial-details .yes {
  color: #7fb978;
}

.pdgm .accordion-button:focus {
  box-shadow: none;
}

.bg-column {
  background-color: #f8f8f8;
  padding: 15px;
}

.bg-column h3 {
  font-weight: 700 !important;
  font-size: 1.25rem !important;
}

/* .accordion-collapse {
  background-color: transparent !important;
} */

.align-tabs .head-clr {
  background-color: #fff;
  /*padding: 4px 15px;*/
  margin-bottom: 15px;
}

.align-tabs .head-clr > div {
  margin-right: 280px;
}

/* .align-tabs .productivity-tabs .first-row{
  background-color: #fff;
} */

.css-b62m3t-container,
.react-select__container,
.css-3iigni-container {
  padding: 12px 0px 0px 0px;
}

.dropdown-flex .react-select__option {
  padding: 0 0.5rem !important;
  display: flex !important;
  align-items: center !important;
}

.page-title {
  padding: 12px 0px;
}

.align-tabs .call2action {
  top: 32px;
}

.refresh-span img {
  width: 18px !important;
  margin: 0 12px !important;
  cursor: pointer !important;
}

.css-fshbj9-control,
.css-14730ip-control,
.react-select__control {
  min-height: 28px !important;
}

.css-tlfecz-indicatorContainer,
.react-select__indicatorContainer {
  padding: 6px 8px !important;
}

.clinician-section,
.status-bar-section {
  position: relative;
  /* top: -40px!important; */
  z-index: 0 !important;
}

.modebar-container {
  top: 34px !important;
}

.zindex {
  z-index: 1;
  position: relative;
}

.user-graph {
  width: 100%;
}

.infobox {
  margin: 0;
  padding: 0;
}

.infobox .title {
  font-weight: bolder !important;
}

.infobox span {
  font-weight: bold;
}

.infobox small,
.infobox span {
  font-size: 12px;
  margin: 0px;
}

.infobox hr {
  color: #e9e7e7;
  margin: 5px 0px;
}

.pending-orders .react-datepicker__input-container,
.pending-orders .react-datepicker-wrapper {
  display: inline !important;
}

.info-details .detail {
  z-index: 1002 !important;
}

.info-details .js-plotly-plot .plotly .main-svg {
  z-index: 1 !important;
}

.info-details .js-plotly-plot .plotly .main-svg:first-child {
  transform: translateY(-63px);
}

.info-details .js-plotly-plot .plotly .main-svg:nth-child(2) {
  position: relative !important;
  top: 120px !important;
}

.info .js-plotly-plot .plotly .main-svg {
  top: -14px;
}

.info-details .legend {
  transform: translateX(53px) translateY(290px) !important;
}

.height-adjust-graph {
  height: 470px;
}

.height-adjust-graph-aged-ar {
  height: 350px;
}

.filter-icon {
  padding: 4px 10px !important;
  top: 0 !important;
}

.pad-reduce .css-b62m3t-container,
.pad-reduce .react-select__container {
  padding: 0px 0px;
}

.filter-group .group .active {
  display: inline-block;
  background: #066ed2;
  color: #ffffff;
  border-radius: 4px;
  line-height: 36px !important;
}

.filter-group {
  border-radius: 5px;
  display: inline-block;
}

.filter-group .group .filter {
  display: inline-block;
  font-size: 12px;
  width: 85px;
  text-align: center;
  cursor: pointer;
}

.form-check-input:focus {
  box-shadow: none;
}

.js-plotly-plot .plotly .main-svg {
  background-color: transparent !important;
}

.details-button img {
  margin-right: 6px;
}

.form-control:focus {
  box-shadow: none;
}

.primary-details .container {
  margin: 0px;
}

.container-body .container {
  margin: 0px;
}

.primary-Details-tab {
  height: auto;
}

.leftTab {
  border: 1px solid white;
  border-radius: 5px;
  background-color: #f8f8f8;
  /* margin-right: 20px; */
  height: 100vh !important;
}

.rightTab-navbar {
  font-size: small;
}

.primary-details .nav-tabs {
  margin-top: 0px;
  padding: 0px;
  margin-bottom: 3px !important;
}

.rightTab {
  padding: 0px;
}

.primary-details .nav-link {
  padding: 5px;
  margin-right: 12px !important;
  font-size: small;
}

.primary-details .page-tabs .nav-link {
  font-size: 12px !important;
}

.primary-details .button-default {
  font-size: 13px;
}

.section-header-Emr {
  font-size: 13px;
  font-weight: bold;
}

.tabs-section1 .container {
  padding-left: 0px;
}

.tabs-section1 .rightTabInputBoxFormLabel {
  font-size: 0.7rem;
}

.tabs-section1 .emr-button {
  font-size: 12px;
}

.prod_select_option .css-1396x98-ValueContainer,
.prod_select_option .react-select__value-container {
  height: auto;
}

.settings .tabs-section1 {
  margin: 0;
}

.table-header-actions .toggle-cls-mini,
.table-header-actions .toggle-cls {
  position: relative;
}

.table-header-actions .popover {
  width: 276px;
  z-index: 4;
  top: 0 !important;
  transform: translate(-60px, -44px) !important;
}

.table-header-actions .popover-arrow {
  transform: translate(0px, 58px) !important;
}

#popover-contained .popover-body > div {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__header--custom button {
  border: 1px solid #a3a3a3;
}

.react-datepicker__header--custom select {
  padding: 2px 0px;
  background-color: #fff;
  border: 1px solid #a3a3a3;
}

/* toast custom  */

.toast-row {
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.toast-row .error {
  background-color: #dc3745;
  color: #fff;
}

.toast-row .success {
  background-color: #1a8754;
  color: #fff;
}

.border-left {
  border-left: 5px solid;
}

.aged-tittle {
  height: 30px;
  margin-bottom: 12px;
}

.aged-tittle p {
  font-size: 18px !important;
}

.show-cell {
  background: white;
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}

.show-name {
  font-weight: bold;
}

.show-presenter {
  font-style: italic;
}

/* AG-Grid Table */

.row-bold .col {
  font-weight: bold;
}

.ag-body-viewport [col-id="a"],
.ag-body-viewport [col-id="b"],
.ag-body-viewport [col-id="c"],
.ag-body-viewport [col-id="d"],
.ag-body-viewport [col-id="e"],
.ag-body-viewport [col-id="f"],
.ag-body-viewport [col-id="g"],
.ag-body-viewport [col-id="h"],
.ag-body-viewport [col-id="i"],
.ag-body-viewport [col-id="j"] {
  /* color: purple; */
  padding-left: 0px !important;
}

/* .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{
  padding-left: 0px!important;
}

[col-id="balance"] {
  padding-left: 17px!important;
} */

/* .full-width-panel .ag-center-cols-viewport
{
  overflow-x: hidden;
} */

.full-width-panel .ag-root .ag-header {
  display: none;
}

.full-width-panel .ag-theme-alpine .ag-row {
  border-color: transparent;
}

.full-width-panel .ag-theme-alpine .ag-root-wrapper {
  border-color: transparent;
}

.row-format-bold {
  font-weight: bold;
}

/* AG-Grid Table */

/* Billing Batch Report styles */

.Export-report {
  display: inline-block;
  text-align: right;
  padding: 6px 12px;
  /* margin-left: 10px; */
  background: #e7f5ff;
  border-radius: 5px;
  cursor: pointer;
}

/* Billing Batch Report styles */

.details-btn {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #066ed2 !important;
}

/* Aged-ar */

.aged-ar-row .accordion-header {
  border: transparent !important;
}

.aged-ar-row .accordion-item {
  border: transparent !important;
}

.aged-ar-row .accordion-button {
  display: block !important;
  padding: 0 !important;
}

.aged-ar-row .accordion-button::after {
  display: inline-block;
  order: -1;
  margin-left: 0;
  margin-right: 0.5em;
  top: calc(50% - 10px);
  position: absolute;
  margin-left: 4px !important;
  width: 18px !important;
  height: 18px !important;
  background-size: 16px;
}

.aged-ar-row .accordion-button:not(.collapsed) {
  background-color: transparent;
  border: none;
}

.aged-ar-row .accordion-button:not(.collapsed) {
  color: #212529 !important;
}

.aged-ar .split-date {
  width: 100%;
}

.aged-ar .Export-report {
  padding-top: 13px;
  padding-bottom: 13px;
}

/* after code splitting */

/* react select remove seperator */

.react-select__indicator-separator {
  width: 0 !important;
}

/* react select remove seperator */

.split-date .card-tittle {
  font-size: 12px;
  line-height: 15px;
  color: #454545;
  margin-bottom: 10px;
}

.page-tabs .nav-tabs .nav-item:first-child {
  margin-bottom: 0px;
}

.page-tabs .nav-tabs .nav-link.active {
  color: #066ed2 !important;
  border-bottom: 4px solid #066ed2 !important;
  font-weight: 400 !important;
}

.page-tabs .nav-link {
  color: #302d2d !important;
  font-size: 14px !important;
  border: none !important;
  margin-right: 42px;
  padding-left: 0px;
}

.productivity-columns {
  padding: 10px 0;
}

.productivity-columns .productivity-column {
  position: relative;
  margin-bottom: 20px;
}

.productivity-column .title {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #454545;
  margin: 5px 0;
}

.productivity-tabs {
  width: 100%;
}

.productivity-tabs .right {
  padding: 10px;
  padding-bottom: 0 !important;
}

.productivity-tabs .reports-column .row > div {
  padding-top: 0px !important;
  margin-top: 5px !important;
  margin-bottom: 20px;
  text-align: left;
}

.reports-column .title {
  font-family: "Outfit";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  margin: 5px 0;
}

.ag-cell-value .blue-button:hover {
  background: #1e62a4 !important;
  color: #fff !important;
  border: 0.2px solid #0d6efd !important;
}

.ag-cell-value .blue-button {
  background: #e7f5ff !important;
  border-radius: 5px !important;
  border: 0.2px solid #e5e5e5 !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #212529 !important;
  padding: 4px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  cursor: pointer !important;
}

.update-modal .modal-header {
  background: #e7f5ff;
  font-size: 0.5rem !important;
}

.update-modal .modal-title {
  font-size: 1rem;
  font-weight: 600;
}

.update-modal .modal-body {
  display: inherit !important;
  justify-content: normal !important;
  /* background-color: #f9f9f9; */
}

/* commented to add spacing in modal body */
/* .modal-body {
  padding: 0 0.5rem !important;
} */

.pdgm-modal .tab-right-nav {
  overflow-y: scroll;
  height: 464px;
}

.tab-container .tab-right-nav {
  background: #f8f8f8;
  border-radius: 8px;
  position: relative;
  width: 100%;
}

.head-modal {
  font-size: 12px;
  font-weight: 600;
  color: #066ed2 !important;
}

.head-modal .default-div p {
  font-size: 14px;
}

.pdgm-modal .modal-body .css-b62m3t-container {
  padding: 0px;
  margin: 5px 0px;
}

.modal-body small {
  display: block;
  margin-bottom: 6px;
}

.pdgm-modal .update-nav-body {
  width: auto;
}

.pdgm-modal .nav-body {
  padding: 0;
}

.pdgm-modal .nav-body .comment {
  margin: 0px;
  margin-bottom: 5px;
}

.pdgm-modal .border-btn.btn {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 10px 5px;
}

.pdgm-modal .date-icon {
  display: inline;
  position: absolute;
  left: 0;
  top: 46%;
  transform: translateY(-50%);
  margin-left: 15px;
}

.tab-container .update-nav-body {
  overflow-y: auto;
  max-height: 350px;
}

.tab-container .nav-body {
  color: #454545;
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  border-radius: 8px;
}

.tab-container .nav-body .comment {
  background: #ffffff;
  margin: 5px 5px 20px 5px;
  padding: 10px;
  border-radius: 8px;
}

.tab-right-nav .text {
  font-size: 12px;
  color: #888888;
}

.tab-container .tab-right-nav .nav-body .author-info div:first-child {
  color: #454545;
  text-align: left;
  font-size: 13px;
  /* font-weight: bold; */
  display: inline-block;
  width: 80%;
}

.tab-container .tab-right-nav .nav-body .author-info div:last-child {
  color: #454545;
  font-size: 12px;
  display: inline-block;
}

.tab-container .tab-right-nav .comment .data {
  color: #454545;
  text-align: left;
  font-size: 12px;
  padding: 10px 0;
}

.tab-container .tab-right-nav .comment .action-date span:first-child {
  margin-right: 5px;
}

.tab-container .tab-right-nav .comment .action-date span:last-child {
  font-size: 12px;
  color: #454545;
}

.page-tabs .nav-tabs .nav-item:first-child .page-title {
  font-size: 20px;
}

.active-cards {
  box-shadow: 5px 5px 3px lightgrey;
}

.active-cards1 {
  box-shadow: 0.5px 4px 0px black;
}

.pdgm-manage .search-mrn,
.pdgm-manage .sort-by {
  display: flex;
  background-color: #f8f8f8;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border-radius: 5px;
}

.pdgm-manage .filters input {
  border: white;
  background: #f8f8f8;
  font-size: 14px;
}

.pdgm-manage .filters img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.pdgm-manage .sortBy {
  background-color: white !important;
}

.pdgm-manage .sortBy .btn-secondary {
  background-color: #f8f8f8 !important;
  font-size: 14px;
  margin-top: 6px;
}

.pdgm-manage .sortBy img {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.pdgm-manage .filtersRows input {
  border: white;
  background: #f8f8f8;
  font-size: 16px;
  text-align: center;
  padding-left: 0px;
  margin-left: 0px;
}

.accordion-button:focus {
  box-shadow: none;
}

.pdgm small {
  font-size: 12px;
  display: block;
  margin-bottom: 6px;
  color: #454545;
}

.fill-height {
  height: 100vh;
  background-color: #e2f0ff;
}

.pdgm small.mt-1 {
  font-weight: bold;
}

.blue-button:hover {
  background: #1e62a4 !important;
  color: #fff !important;
  border: 0.2px solid #0d6efd !important;
}

.blue-button {
  background: #e7f5ff !important;
  border-radius: 5px !important;
  border: 0.2px solid #e5e5e5 !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #212529 !important;
  padding: 4px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  cursor: pointer !important;
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus,
button:focus:not(:focus-visible) {
  box-shadow: none;
}

.buttons {
  border: 1px solid White !important;
  background-color: white !important;
  font-size: 12px !important;
  color: black !important;
}

.button-default {
  font-size: 12px !important;
}

.activeButton {
  color: #fff !important;
  background-color: #0b5ed7 !important;
  border-color: #0a58ca !important;
}

.buttons:hover {
  color: #fff !important;
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}

.action-crud {
  background-color: transparent !important;
  font-size: 11px !important;
  font-weight: 700;
  padding: 5px 8px 5px 8px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border: none !important;
}

.deleteModalTitle {
  text-align: center;
  width: 100%;
  box-sizing: border-box !important;
}

.deleteModalButton1 {
  margin-right: 20px !important;
}

.ag-theme-alpine
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-ltr
  .ag-context-menu-open
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-ltr
  .ag-has-focus
  .ag-full-width-row.ag-row-focus
  .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-alpine
  .ag-rtl
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-rtl
  .ag-context-menu-open
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine
  .ag-rtl
  .ag-has-focus
  .ag-full-width-row.ag-row-focus
  .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: none !important;
}

.chart_loader {
  margin: auto;
  padding: 13rem 16rem;
  font-size: 0.8rem;
}

.prod_user {
  height: 450px;
  text-align: center;
}

.invalid-text {
  color: red !important;
}

.graph-tittle,
.js-plotly-plot .plotly .main-svg .infolayer .g-gtitle,
.js-plotly-plot .plotly .main-svg .infolayer .g-gtitle .gtitle {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  text-align: center !important;
  color: #454545 !important;
  margin-bottom: 10px !important;
}

.mono-space
  .plotly
  .main-svg
  .cartesianlayer
  .subplot.xy
  .yaxislayer-above
  text,
.mono-space
  .plotly
  .main-svg
  .cartesianlayer
  .subplot.xy
  .xaxislayer-above
  text {
  font-family: "Courier New", Courier, monospace !important;
}

/* .table-data .row{
  margin-top: 8px;
  margin-bottom: 8px;
} */
/* .table-data td{
  padding-top: 1rem!important;
  padding-bottom: 0!important;
} */
.table-data .row .col:first-child {
  text-align: left;
  padding-left: 26px;
}

.table-data thead {
  background-color: #f8f8f8;
  border: 1px solid #babfc7;
  font-size: 11px;
}

.table-data thead th {
  padding-top: 15px;
  padding-bottom: 15px;
}

.table-data thead .col {
  font-size: 12px;
}

.table-data tbody .col {
  font-size: 12px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.table-data tbody td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.table-data {
  border: 1px solid #babfc7;
}

.link-elg {
  color: #046ed2 !important;
  cursor: pointer !important;
}

@media (min-width: 576px) {
  .pdgm-modal .modal-dialog {
    max-width: 650px;

    /* change margin to resolve modal overlap issue */
    /* margin: 1.75rem auto; */
    margin: 3.25rem auto;
  }
}

/* after code splitting */

.sc-AykKC.ceHfpt {
  z-index: 9999;
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s linear 0s, visibility 0.2s linear 0.3s;
  height: 100vh;
  width: 100vw;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  background: rgb(247, 247, 247);
}

.flex-responsive > div {
  flex: 1;
}

.claim-status-rel {
  height: 100%;
}

.toast-conatiner .toast {
  margin: auto;
  text-align: center;
  color: #e7f5ff;
}

.white-bg {
  background-color: #ffffff !important;
}

.flex2 {
  flex: 2;
}

.flex1 {
  flex: 1;
}

.aged-export {
  padding-top: 0.65rem !important;
  padding-bottom: 0.65rem !important;
}

@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}

.serverExportModalHeight {
  height: 350px !important;
}
