.add-record-button {
  float: right;
  font-size: 12px !important;
}

.primary-details {
  /* max-width: 1385px; */
  padding-left: 0px;
}

.primary-details .leftTab {
  padding-left: 0px;
  padding-right: 0px;
}

.primary-details .ag-header-cell {
  padding-left: 10px;
}

.primary-details .ag-header-cell-text {
  font-size: 10px;
}

.react-datepicker-wrapper {
  display: block;
}

.DropDownToggle.dropdownseperator.dropdown-toggle.btn.btn-primary:hover {
  background-color: transparent;
  color: #000;
}

.DropDownToggle.dropdownseperator.dropdown-toggle.btn.btn-primary:focus {
  box-shadow: none;
}

.leftTab-buttons {
  width: 160px;
  font-size: 12px;
  margin-top: 8px;
  border: 1px solid white;
  color: black;
  background-color: white;
  border-radius: 5px;
}

.tabs-section1 h6 {
  font-size: 14px;
  font-weight: bold;
  padding-top: 3px;
}

.rightTabDropDown {
  margin-top: 0px;
  /* width:250px; */
  margin-right: 15px;
  margin-bottom: 0px !important;
}

.rightTabDropDown1 {
  margin-top: 0px;
  /* width:250px; */
  /* margin-right: 15px; */
}

.rightTabDropDownFormLabel {
  margin-bottom: 2px;
  font-size: 11px;
}

.rightTabDropDownFormSelect,
.rightTabInputBox {
  background-color: #f8f8f8;
  /* width:220px; */
  font-size: 12px !important;
}

#rightTabInputBox {
  background-color: #f8f8f8;
  /* width:260px; */
  font-size: small;
}

.section {
  margin-bottom: 10px;
}

.updateButton {
  margin-top: 10px;
  font-size: 13px;
}

.primary-details .btn-primary {
  margin: 5px 0px;
  float: "right";
}

.new-record-modal .modal-header {
  background: #e7f5ff;
  font-size: 2px !important;
  padding: 10px;
}

.new-record-modal .modal-title {
  font-size: 0.8rem;
  font-weight: 600;
}

.new-record-modal .btn-close {
  font-size: 8px;
}

.new-record-modal .modal-lg {
  max-width: 600px;
}

.new-record-modal .form-label {
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
}

.new-record-modal .modal-body {
  padding: 1px;
}

.new-record-modal .form-control {
  font-size: 13px;
}

.new-record-modal small {
  font-size: 0.6rem;
}

.new-record-modal .mb-4 {
  margin-bottom: 2px !important;
}

.new-record-modal .mt-4 {
  margin-top: 8px !important;
  font-size: 0.7rem !important;
}

.toast-conatiner .toast {
  margin: auto;
  text-align: left;
}

.DropDown {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.DropDownToggle {
  background-color: white;
  color: black;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 13px;
}

.DropDownMenu {
  width: 100%;
}

.sftp-settings {
  margin-top: 25px;
}

.clearing-house-leftTab {
  height: 760px;
}

.account-admin hr {
  margin: 0.6rem;
}

.account-admin .mb-3 {
  margin-bottom: 0.7rem !important;
}

.next-bar {
  margin-top: 40px;
}

.ag-theme-alpine .ag-ltr input[class^="ag-"]:not([type]),
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="text"],
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="number"],
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="tel"],
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="date"],
.ag-theme-alpine .ag-ltr input[class^="ag-"][type="datetime-local"],
.ag-theme-alpine .ag-ltr textarea[class^="ag-"] {
  padding-left: 6px;
  height: 40px;
}

.tabs-section1.rightTab {
  height: auto;
}

.settings-date {
  right: 15px !important;
  /* left: auto !important; */
}

.settings-input input {
  padding-left: 15px;
}

/* aggrid pagination custom  */

.pagination-custom {
  border: 1px solid #babfc7;
  /* border-top-color: #babfc7; */
  border-top-color: var(--ag-border-color, #babfc7);
  color: #181d1f;
  color: var(--ag-secondary-foreground-color, var(--ag-foreground-color, #181d1f));
  height: 48px;
}

.pagination-custom .ag-paging-row-summary-panel {
  margin-left: 18px;
  margin-right: 18px;
}

.pagination-custom .first-paging>span,
.pagination-custom .second-paging .ag-paging-description>span {
  padding-left: 2px;
  padding-right: 2px;
}

/* aggrid pagination custom  */


/* data-playground */

.border-aggrid {
  border: 1px solid;
  border-color: #babfc7;
  margin-top: 46px;
}

.dataplayground-Range .calendar {
  margin-bottom: 0;
  padding-top: 6px;
  padding-bottom: 8px;
  height: auto;
  width: auto;
  /* background-color: #f8f8f8; */
  border-color: hsl(0, 0%, 80%);
  border-width: 1px;
  border-style: solid;

}

.dataplayground-Range .calendar .icon {
  left: 0;
}

.dataplayground-Range .calendar .text {
  /* color: #6c757d !important; */
  /* font-size: 0.59rem; */
  left: auto;
}

.react-select--is-disabled {
  opacity: 0.5;
}

@media (min-width: 1365.98px) and (max-width: 1367px) {
  .dataplayground-Range .calendar .text {
    font-size: 0.59rem;
  }
}

/* data-playground */



/* 
.stick .leftTab {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
} */